html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Gilroy-Regular";
}
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

input,
select {
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: auto;
}

/* Navbar */
#navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  margin: calc(0.8 * 60px) auto;
  z-index: 1000000;
  max-width: 1440px;
}

.navbar-brand {
  margin-left: calc(0.8 * 108px);
}

.navbar-button {
  margin-right: calc(0.8 * 75px);
}
/* Navbar */

#carouselExampleDark {
  width: 100%;
}

/* TopSection */
#top-section {
  min-height: 740px;
  max-height: 1440px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;

  padding: 0 calc(0.8 * 108px);
  display: flex;
  flex-direction: column;
  border: 0;
}

.T1 {
  background-image: url("../images/hero-banner-1.png");
}

.T2 {
  background-image: url("../images/hero-banner-2.png");
}

.T3 {
  background-image: url("../images/hero-banner-3.png");
}

.T4 {
  background-image: url("../images/hero-banner-4.png");
}

#top-section.T1 {
  background-position: 0 0;
}

#top-section.T2 {
  background-position: 51% 0;
}

#top-section.T3 {
  background-position: 60% 0;
}

#top-section.T4 {
  background-position: 48% 0;
}

@media screen and (min-width: 2000px) {
  #top-section.T1 {
    background-position: 40% 0;
  }

  #top-section.T2 {
    background-position: 70% 0;
  }

  #top-section.T3 {
    background-position: 70% 0;
  }

  #top-section.T4 {
    background-position: 80% 0;
  }
}

.top-section-content {
  margin-top: calc(0.8 * 217px);
  height: 414px;
}

.top-section-heading {
  width: calc(0.8 * 523px);
  margin-bottom: calc(0.8 * 10px);

  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: calc(0.8 * 74px);
  line-height: calc(0.8 * 89px);
  letter-spacing: -0.025em;
  color: #000000;
}

.top-section-subheading {
  width: calc(0.8 * 429px);
  margin-bottom: calc(0.8 * 43px);

  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: calc(0.8 * 24px);
  line-height: calc(0.8 * 29px);
  letter-spacing: -0.025em;
  color: #000000;
}

/* TopSection */

/* Button */
.button {
  border-radius: 6px;
  border: 1px solid #000000;
  background: transparent;

  width: calc(0.8 * 250px);
  height: calc(0.8 * 80px);

  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: calc(0.8 * 15px);
  line-height: calc(0.8 * 13px);
  letter-spacing: -0.025em;
  color: #000000;
}

.button.dark {
  background: #000000;
  color: #ffffff;
}

.button.bright {
  background: #fe5000;
  color: #ffffff;
  border: 1px solid #fe5000;
}

.button.bright:hover {
  background: transparent;
  color: #fe5000;
}

.button:hover {
  background: #000000;
  color: #ffffff;
  transition: ease-in-out 0.3s;
}
@media screen and (min-width: 769px) {
  .button.arrow {
    width: fit-content;
    min-width: 372px;
    padding: 0 calc(0.8 * 47px);
  }

  .button-arrow {
    margin-left: calc(0.8 * 35px);
  }
}

.button.dark:hover {
  background: transparent;
  color: #000000;
  transition: ease-in-out 0.3s;
}

/* Button */

/* Carousel */
.carousel-indicators {
  position: absolute;
  top: 540px;
  left: calc(0.8 * 108px);

  display: flex;
  justify-content: flex-start;
  width: fit-content;
  margin: calc(0.8 * 82px) 0 0 -3px;
}

.carousel-indicators [data-bs-target] {
  background-color: #ffffff;
  opacity: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative !important;
  margin-right: calc(0.8 * 10.5px);
  /* border: 0.2px solid #000000; */
}

.carousel-indicators .active {
  background-color: #000000;
}
/* Carousel */

/* User Agreement */
.agreementDiv {
  background-color: #f9f8f7;
  padding-top: 130px;
}

.agreementDiv h1 {
  text-align: center;
  padding-top: 2%;
}

.agreementDiv p {
  text-align: left;
}

.agreementDiv h4 {
  text-align: left;
}

.agreementDiv p a {
  text-decoration: none;
  color: #fe5000;
}

.agreementDiv .nav-links {
  width: 30%;
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.agreementDiv .newStyle {
  text-decoration: none;
  font-weight: bold;
}

@media all and (max-width: 500px) {
  .agreementDiv .text {
    margin-left: 20px;
    margin-right: 15px;
  }

  .agreementDiv .nav-links #use {
    margin-left: 70px;
  }
}

@media all and (max-width: 800px) and (min-width: 501px) {
  .agreementDiv .text {
    margin-left: 20px;
    margin-right: 15px;
  }
}

@media all and (max-width: 1023px) and (min-width: 801px) {
  .agreementDiv .text {
    margin-left: 20px;
    margin-right: 15px;
  }
}

@media all and (min-width: 1024px) {
  .agreementDiv .text {
    margin-left: 200px;
    margin-right: 170px;
  }
}
/* User Agreement */

/* middleSection */
.middle-section {
  /* min-height: 70vh; */
  background-color: #e3fff9;
  width: 100%;
}
/* middleSection */

/* Join */
.join-waitlist {
  padding: calc(0.8 * 51px) calc(0.8 * 66.5px) calc(0.8 * 51px)
    calc(0.8 * 108px);
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 1250px) {
  .join-waitlist {
    padding: calc(0.8 * 51px) calc(0.8 * 108px) calc(0.8 * 51px)
      calc(0.8 * 108px);
  }
}

.join-waitlist-text {
  min-width: calc(0.8 * 167px);
  height: calc(0.8 * 29px);

  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: calc(0.8 * 24px);
  line-height: calc(0.8 * 29px);

  letter-spacing: -0.025em;
  color: #000000;
}

.join-waitlist-line {
  border: none;
  border-top: 1px solid #000000;
  width: 100%;
  margin-left: calc(0.8 * 37px);
}
/* Join */

/* Waitlist */
.waitlist-section {
  padding: 0 calc(0.8 * 66.5px) calc(0.8 * 80px) calc(0.8 * 108px);
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.waitlist-section-image img {
  width: calc(0.8 * 398px);
  margin-top: 30px;
}

@media screen and (min-width: 1300px) {
  .waitlist-section {
    padding: 0 calc(0.8 * 108px) calc(0.8 * 100px) calc(0.8 * 108px);
    column-gap: 5%;
    /* justify-content: space-evenly; */
  }
  .waitlist-section-image img {
    width: calc(1 * 359px);
  }
}

.waitlist-section-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: calc(0.8 * 71px);
}

.waitlist-section-heading {
  max-width: calc(0.8 * 1063px);
  margin-bottom: calc(0.8 * 22px);

  font-family: "Gilroy-Bold";
  font-size: calc(0.7 * 64px);
  line-height: calc(0.8 * 77px);
  letter-spacing: -0.025em;

  color: #000000;
}
.waitlist-section-subheading {
  max-width: calc(0.8 * 739px);
  margin-bottom: calc(0.8 * 90px);

  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: calc(0.8 * 24px);
  line-height: calc(0.8 * 29px);
  letter-spacing: -0.025em;
  color: #000000;
}

.waitlist-section-email-button {
  display: flex;
  flex-direction: row;
  column-gap: calc(0.8 * 12px);
  row-gap: calc(0.8 * 12px);
  flex-wrap: wrap;
  margin-bottom: 45px;
}
/* Waitlist */

/* Input */
input {
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 5000s;
}

input:focus {
  outline: none;
}

.input {
  max-width: calc(0.8 * 406px);
  width: calc(0.8 * 406px);
  height: calc(0.8 * 80px);

  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 0 calc(0.8 * 26px);

  font-family: "Gilroy-Regular";
  font-size: calc(0.9 * 24px);
  line-height: calc(0.9 * 29px);
  color: #000000;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #000000;
  opacity: 0.3;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
  opacity: 0.3;
}

::placeholder {
  color: #000000;
  opacity: 0.3;
}
/* Input */

/* Survey */
.survey-section {
  padding: calc(0.8 * 46px) calc(0.8 * 66.5px) calc(0.8 * 100px)
    calc(0.8 * 108px);
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.survey-section-image img {
  width: calc(0.8 * 398px);
}

@media screen and (min-width: 1300px) {
  .survey-section {
    padding: calc(0.8 * 46px) calc(0.8 * 108px) calc(0.8 * 100px)
      calc(0.8 * 108px);
    column-gap: 5%;
    /* justify-content: space-evenly; */
  }
  .survey-section-image img {
    width: calc(1 * 359px);
  }
}

.survey-section-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0.8 * 71px);
}

.survey-section-heading {
  max-width: calc(0.8 * 926px);
  margin-bottom: calc(0.8 * 40px);

  font-family: "Gilroy-Bold";
  font-size: calc(0.7 * 64px);
  line-height: calc(0.8 * 77px);
  letter-spacing: -0.025em;

  color: #000000;
}

.survey-section-subheading {
  max-width: calc(0.8 * 245px);
  margin-bottom: calc(0.8 * 56px);

  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: calc(0.8 * 24px);
  line-height: calc(0.8 * 29px);
  letter-spacing: -0.025em;
  color: #000000;
}
/* Survey */

/* Success */
.success-section {
  padding: calc(0.8 * 46px) calc(0.8 * 66.5px) calc(0.8 * 80px)
    calc(0.8 * 108px);
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.success-section-image {
  width: calc(0.8 * 398px);
  text-align: center;
  /* margin-right: calc(0.8 * 71px); */
}

.success-section-image img {
  width: calc(0.8 * 284px);
  /* margin-right: calc(0.8 * 71px); */
}

.success-section-heading-invisible {
  display: none;
}

@media screen and (min-width: 1300px) {
  .success-section {
    padding: calc(0.8 * 46px) calc(0.8 * 108px) calc(0.8 * 80px)
      calc(0.8 * 108px);
    column-gap: 5%;
    /* justify-content: space-evenly; */
  }

  .success-section-image {
    width: calc(1 * 359px);
  }

  .success-section-image img {
    width: calc(1 * 284px);
  }

  .success-section-heading-invisible {
    display: block;
    color: transparent;
  }
}

.success-section-middle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0.8 * 71px);
}

/* .success-section-right {
  width: 15%;
  margin-right: calc(0.8 * 71px);
} */

.success-section-heading {
  max-width: calc(0.8 * 926px);
  margin-bottom: calc(0.8 * 40px);

  font-family: "Gilroy-Bold";
  font-size: calc(0.7 * 64px);
  line-height: calc(0.8 * 77px);
  letter-spacing: -0.025em;

  color: #000000;
}

.success-section-subheading {
  max-width: calc(0.8 * 433px);
  margin-bottom: calc(0.8 * 56px);

  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: calc(0.8 * 24px);
  line-height: calc(0.8 * 29px);
  letter-spacing: -0.025em;
  color: #000000;
}
/* Success */

/* Footer */
#footer-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: calc(0.8 * 90px) calc(0.8 * 66px) calc(0.8 * 76px) calc(0.8 * 108px);
}

.footer-logo {
  width: 40%;
  margin-right: 10%;
}

.footer-text {
  width: 50%;

  font-family: "Gilroy-Regular";
  font-size: calc(0.8 * 24px);
  line-height: calc(0.8 * 29px);
  letter-spacing: -0.025em;
  color: #000000;
}

.footer-text-info {
  opacity: 0.5;
}

.footer-text-terms {
  margin-top: calc(0.8 * 41px);

  font-size: calc(0.9 * 14px);
  line-height: calc(0.8 * 17px);
  display: flex;
  flex-direction: row;
  column-gap: 20%;
}

.footer-text-terms a {
  color: #000000;
  opacity: 0.5;
}

.footer-text-terms a:hover {
  text-decoration: underline;
  opacity: 1;
  transition: ease-in-out 0.2s;
}
/* Footer */

/* AnimatedSection */
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-sectionLeft {
  opacity: 0;
  transform: translateX(-20vh);
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-sectionLeft.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-sectionRight {
  opacity: 0;
  transform: translateX(20vh);
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-sectionRight.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
/* AnimatedSection */
